
export class Config {

    static organizationId = '595febaac6ac8002619fd1e4';
    static organizationName = "SABHAI THAI";
    static appVersion = '2.31.00';

    static cipher: string = 'KPdzpXFGaUkAFHwj5FBy82B6'; 

    static isCanuucan = 'false';

    static pickupTypes = [ 'DELIVERYTYPE02', 'DELIVERYTYPE07' ];
    static zendeskAccountUrl = 'moduurnhelp.zendesk.com';
    static zendeskUserField=1500009211882;
    static zendeskUserFormId= 1500002580982;
    
    constructor() { }
}
